import React, { useContext } from "react";
import Layout from "../components/Layout";
import { Sidebar } from "../components";
import TrainingMaterial from "../components/Training";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import { TrainingItems } from "../data";

const Training = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}

	return (
		<>
			{user.user_role === "EMPLOYEE" ||
			user.user_role === "INTERN" ||
			user.user_role === "TRAINEE" ||
			user.user_role === "MANAGER" ||
			user.user_role === "CONSULTANT" ||
			user.user_role === "RMG" ? (
				<Layout sidebar={<Sidebar sidebarLinks={TrainingItems} />}>
					<Route path="/training" component={TrainingMaterial} />
				</Layout>
			) : null}
		</>
	);
};

export default Training;
