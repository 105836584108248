import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthContext";
import Table from "./Table";
import {
	lmsTableHead,
	lmsTableHeadForManager,
	lmsAppliedLeaveHead,
	dropDownPagination,
	wfhTableHead,
	wfhTableHeadForManager,
} from "../data";
import Popup from "./Popup";
import LmsLeaveDetails from "./LmsLeaveDetails";
import Button from "./Button";
import LmsCards from "./LmsCards";
import { useStateContext } from "../contexts/ContextProvider";
import {
	LEAVEDETAILS,
	LMSLATESTLEAVE,
	DELETELEAVEREQUEST,
	LATESTWORKMODE,
	WFHDETAILS,
	DELETEWFH,
} from "../Constants";
import ApiCall from "../utils/ApiCall";
import SweetAlert from "../utils/SweetAlert";
import "tailwindcss/tailwind.css";
import Loader from "./Loader";
import Textarea from "./Textarea";
import Dropdown from "./Dropdown";
import NoDataImg from "../images/no_data.svg";

const LmsDashboard = () => {
	const year = new Date();
	const fullYear = 2024;
	const comingYear = 2025;

	const { authTokens, user } = useContext(AuthContext);
	const { cardData, reportTo } = useStateContext();
	const [popupItem, setPopupItem] = useState(null);
	const [approvalPopupItem, setApprovalPopupItem] = useState(null);
	const [latestLeave, setLatestLeave] = useState([]);
	const [latestLeaveForApproval, setLatestLeaveForApproval] = useState([]);
	const [descriptionPopup, setDescriptionPopup] = useState("");
	const [descripValue, setDescripValue] = useState("");
	const [decision, setDecision] = useState("");
	const [loading, setLoading] = useState(true);
	const [requestID, setRequestId] = useState("");
	const [lmsYear, setLmsYear] = useState(null);
	const [lmsApproval, setLmsApproval] = useState(
		sessionStorage.getItem("lms-approval-year")
			? JSON.parse(sessionStorage.getItem("lms-approval-year"))
			: `${fullYear}-${comingYear}`
	);
	const [wfh, setWfh] = useState([]);
	const [wfhForApproval, setWfhForApproval] = useState([]);
	const [popupItemWfh, setPopupItemWfh] = useState(null);
	const [approvalPopupItemWfh, setApprovalPopupItemWfh] = useState(null);
	const [decisionWfh, setDecisionWfh] = useState("");
	const [requestIDWfh, setRequestIdWfh] = useState("");
	const [descriptionPopupWfh, setDescriptionPopupWfh] = useState("");
	const [descripValueWfh, setDescripValueWfh] = useState("");
	const [wfhApproval, setWfhApproval] = useState(
		sessionStorage.getItem("wfh-approval-year")
			? JSON.parse(sessionStorage.getItem("wfh-approval-year"))
			: `${fullYear}-${comingYear}`
	);
	const [paginationWfh, setPaginationWfh] = useState(10);
	const [pagination, setPagination] = useState(10);
	const [appliedPopup, setAppliedPopup] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageWfh, setCurrentPageWfh] = useState(1);
	const rowPerPage = 5;

	useEffect(() => {
		const storedLatestLeave = localStorage.getItem("latest-leave");

		if (storedLatestLeave) {
			const parsedData = JSON.parse(storedLatestLeave);
			setLatestLeave(parsedData);
		}
	}, [lmsApproval]);

	useEffect(() => {
		const storedWfh = localStorage.getItem("wfh");

		if (storedWfh) {
			const parsedData = JSON.parse(storedWfh);
			setWfh(parsedData);
		}
	}, [wfhApproval]);

	useEffect(() => {
		fetchData();
		fetchWorkMode();

		const savedPagination = sessionStorage.getItem("lms-pagination-data");
		if (savedPagination) {
			setPagination(JSON.parse(savedPagination));
		}

		const savedPaginationWfh = sessionStorage.getItem("wfh-pagination-data");
		if (savedPaginationWfh) {
			setPaginationWfh(JSON.parse(savedPaginationWfh));
		}

		const savedLmsApproval = sessionStorage.getItem("lms-approval-year");
		if (savedLmsApproval) {
			setLmsApproval(JSON.parse(savedLmsApproval));
		}

		const savedWfhApproval = sessionStorage.getItem("wfh-approval-year");
		if (savedWfhApproval) {
			setWfhApproval(JSON.parse(savedWfhApproval));
		}
	}, [pagination, lmsApproval, wfhApproval, paginationWfh]);

	const fetchData = async () => {
		// console.log("tring");
		try {
			const data = await ApiCall(
				`${LMSLATESTLEAVE}?year_=${lmsApproval}&pg_r=${pagination}`,
				"GET",
				authTokens,
				null
			);
			setLatestLeave(data.data.leave_application1);
			// console.log("data.data.leave_application1", data.data.leave_application1);
			setLmsYear(data.data.available_years);
			// console.log("available_years", data.data.available_years);
			setLatestLeaveForApproval(data.data.leave_application);
			// console.log("latestLeave", data.data);
			setLoading(false);
		} catch (error) {
			console.log("Lms Approval Error fetching data", error);
			setLoading(false);
		}
	};

	const fetchWorkMode = async () => {
		try {
			const data = await ApiCall(
				`${LATESTWORKMODE}?year_=${wfhApproval}&pg_r=${paginationWfh}`,
				"GET",
				authTokens,
				null
			);
			setWfh(data.data.wfh_applicationself);
			setWfhForApproval(data.data.wfh_application);
			// console.log("data", data);
			setLoading(false);
		} catch (error) {
			console.log("Work Mode Error fetching data", error);
			setLoading(false);
		}
	};

	const handlePopupRow = (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const currentData = latestLeave[actualIndex];
		setPopupItem(currentData);
	};

	const handlePopupRowWfh = (rowIndex) => {
		const actualIndex = (currentPageWfh - 1) * rowPerPage + rowIndex;
		const currentData = wfh[actualIndex];
		setPopupItemWfh(currentData);
	};

	const handleManagerPopupRow = async (rowIndex) => {
		const actualIndex = (currentPage - 1) * rowPerPage + rowIndex;
		const id = latestLeaveForApproval[actualIndex].id;
		// setApprovalPopupItem(managerRow);
		try {
			const res = await ApiCall(
				`${LEAVEDETAILS}?id=${id}`,
				"GET",
				authTokens,
				null
			);
			// console.log("ressss", res);
			setApprovalPopupItem(res.data);
			if (res.status == 400) {
				SweetAlert({ title: res.data, icon: "error" });
			}
		} catch (error) {
			console.log("While Requesting data", error);
		}
	};

	const handleManagerPopupRowWfh = async (rowIndex) => {
		const actualIndex = (currentPageWfh - 1) * rowPerPage + rowIndex;
		const id = wfhForApproval[actualIndex].id;
		// setApprovalPopupItem(managerRow);
		try {
			const res = await ApiCall(
				`${WFHDETAILS}?id=${id}`,
				"GET",
				authTokens,
				null
			);
			// console.log("ressss", res);
			setApprovalPopupItemWfh(res.data);
			if (res.status == 400) {
				SweetAlert({ title: res.data, icon: "error" });
			}
		} catch (error) {
			console.log("While Requesting data", error);
		}
	};

	const handleDeleteRowWfh = async (id) => {
		const updateWfh = wfh.filter((item) => item.id !== id);
		const params = {
			id: id,
		};
		//console.log("id", id);
		try {
			const response = await ApiCall(DELETEWFH, "POST", authTokens, params);
			//console.log("response", response);
			if (response.status === 200) {
				SweetAlert({ title: response.data, icon: "success" });
			} else {
				SweetAlert({ title: response.data, icon: "error" });
			}
		} catch (error) {
			console.log("WFH Approval Error fetching data", error);
			setLoading(false);
		}
		fetchWorkMode();
		setPopupItemWfh(null);
	};

	const handleDeleteRow = async (id) => {
		//const updateLatestLeave = latestLeave.filter((item) => item.id !== id);
		const params = {
			id: id,
		};
		//console.log("id", id);
		try {
			const response = await ApiCall(
				DELETELEAVEREQUEST,
				"POST",
				authTokens,
				params
			);
			//console.log("response", response);
			if (response.status === 200) {
				SweetAlert({ title: response.data, icon: "success" });
			} else {
				SweetAlert({ title: response.data, icon: "error" });
			}
		} catch (error) {
			console.log("Lms Approval Error fetching data", error);
			setLoading(false);
		}

		fetchData();
		setPopupItem(null);
	};

	useEffect(() => {
		localStorage.setItem("latest-leave", JSON.stringify(latestLeave));
	}, [latestLeave]);

	useEffect(() => {
		localStorage.setItem("wfh", JSON.stringify(wfh));
	}, [wfh]);

	const handleApprove = (id) => {
		setDecision("accept");
		setRequestId(id);
		// const approveRow = latestLeaveForApproval[rowIndex];
		setDescriptionPopup("show");
		setPopupItem(null);
	};

	const handleReject = (id) => {
		setDecision("reject");
		setRequestId(id);
		// const rejectRow = latestLeaveForApproval[rowIndex];
		setDescriptionPopup("show");
		setPopupItem(null);
	};

	const handleApproveWfh = (id) => {
		setDecisionWfh("accept");
		setRequestIdWfh(id);
		// const approveRow = latestLeaveForApproval[rowIndex];
		setDescriptionPopupWfh("show");
		setPopupItemWfh(null);
	};

	const handleRejectWfh = (id) => {
		setDecisionWfh("reject");
		setRequestIdWfh(id);
		// const rejectRow = latestLeaveForApproval[rowIndex];
		setDescriptionPopupWfh("show");
		setPopupItemWfh(null);
	};

	const handleTextAreaChange = (event) => {
		event.preventDefault();
		setDescripValue(event.target.value);
	};

	const handleTextAreaChangeWfh = (event) => {
		event.preventDefault();
		setDescripValueWfh(event.target.value);
	};

	const handleDescribBox = async (event) => {
		event.preventDefault();
		const params = {
			descripValue,
			decision,
			requestID,
		};
		try {
			const response = await ApiCall(LEAVEDETAILS, "POST", authTokens, params);
			// console.log("response", response.status);
			// if (response.status === 200) {
			// 	SweetAlert({ title: "Approved", icon: "success" });
			// } else {
			// 	SweetAlert({ title: "Sorry!, Something went wrong", icon: "failed" });
			// }
		} catch (error) {
			console.log("Error While Sending Description Data", error);
		}
		if (decision === "accept") {
			SweetAlert({ title: "Approved", icon: "success" });
		} else if (decision === "reject") {
			SweetAlert({ title: "Rejected", icon: "success" });
		} else {
			SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
		}
		// console.log("params", params);
		fetchData();
		setDescriptionPopup(null);
		setApprovalPopupItem(null);
		setDescripValue("");
	};

	const handleDescribBoxWfh = async (event) => {
		event.preventDefault();
		const params = {
			descripValueWfh,
			decisionWfh,
			requestIDWfh,
		};
		try {
			const response = await ApiCall(WFHDETAILS, "POST", authTokens, params);
			// console.log("response", response.status);
			// if (response.status === 200) {
			// 	SweetAlert({ title: "Approved", icon: "success" });
			// } else {
			// 	SweetAlert({ title: "Sorry!, Something went wrong", icon: "failed" });
			// }
		} catch (error) {
			console.log("Error While Sending Description Data", error);
		}
		if (decisionWfh === "accept") {
			SweetAlert({ title: "Approved", icon: "success" });
		} else if (decisionWfh === "reject") {
			SweetAlert({ title: "Rejected", icon: "success" });
		} else {
			SweetAlert({ title: "Sorry!, Something went wrong", icon: "error" });
		}
		// console.log("params", params);
		fetchWorkMode();
		setDescriptionPopupWfh(null);
		setApprovalPopupItemWfh(null);
		setDescripValueWfh("");
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handlePageChangetwo = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handlePageChangethree = (pageNumber) => {
		setCurrentPageWfh(pageNumber);
	};

	const handleLeaveAppPopup = () => {
		// setWfhApproval;
		setAppliedPopup(true);
	};

	const handleLmsApproval = (e) => {
		e.preventDefault();
		// console.log("Pagination", e.target.value);
		setLmsApproval(e.target.value);
		const lmsAppValue = e.target.value;
		sessionStorage.setItem("lms-approval-year", JSON.stringify(lmsAppValue));
	};

	const handleWfhApproval = (e) => {
		e.preventDefault();
		// console.log("Pagination", e.target.value);
		setWfhApproval(e.target.value);
		const wfhAppValue = e.target.value;
		sessionStorage.setItem("wfh-approval-year", JSON.stringify(wfhAppValue));
	};

	const paginatedData = (e) => {
		e.preventDefault();
		setPagination(e.target.value);
		const pageValue = e.target.value;
		sessionStorage.setItem("lms-pagination-data", JSON.stringify(pageValue));
	};

	const paginatedDataWfh = (e) => {
		e.preventDefault();
		setPaginationWfh(e.target.value);
		const pageValueWfh = e.target.value;
		sessionStorage.setItem("wfh-pagination-data", JSON.stringify(pageValueWfh));
	};

	//console.log("reportTo", reportTo);

	return (
		<div className="w-auto my-6 mx-6 py-4 px-2">
			{loading ? (
				<div className="w-full h-[70vh] flex items-center justify-center">
					<Loader />
				</div>
			) : (
				<>
					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-3 pb-6">
						{cardData &&
							cardData.map((item, index) => (
								<LmsCards
									key={index}
									number={item.number}
									title={item.title}
									icons={item.icons}
									color={item.color}
									button={
										index === 0 ? (
											<Button
												onClick={handleLeaveAppPopup}
												classname="w-full bg-cardFourBg border-none px-2 rounded-sm text-xs"
											>
												view
											</Button>
										) : null
									}
								/>
							))}
					</div>

					{user.user_role === "EMPLOYEE" ||
					user.user_role === "INTERN" ||
					user.user_role === "HR" ||
					user.user_role === "MANAGER" ||
					user.user_role === "RMG" ||
					user.user_role === "CONSULTANT" ||
					user.user_role === "TRAINEE" ? (
						latestLeave.length > 0 ? (
							<>
								<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full pb-4 mb-4 mt-4">
									<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3">
										<h4 className="mx-6 text-center table-header-text font-bold text-sm md:text-lg">
											Latest Leave Applications
										</h4>
									</div>
									<Table
										header={lmsTableHead}
										body={latestLeave.map((row) => [
											row.category,
											row.date,
											row.manager_level_approval === false
												? "Rejected"
												: row.manager_level_approval === true
												? "Approved"
												: "Pending",
											row.hr_level_approval === false
												? "Rejected"
												: row.hr_level_approval === true
												? "Approved"
												: "Pending",
										])}
										btnTitle="View"
										paginationNeed={false}
										currentPage={currentPage}
										rowPerPage={rowPerPage}
										paginationBody={latestLeave}
										onPageChange={handlePageChangetwo}
										onClick={handlePopupRow}
										tableStyle="intro-x"
									/>
								</div>
							</>
						) : (
							<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full mt-4 mb-4 pb-4">
								<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3">
									<h4 className="mx-6 text-center table-header-text font-bold text-sm md:text-lg">
										Latest Leave Applications
									</h4>
								</div>
								<h3 className="text-center flex flex-col justify-center items-center intro-x my-4">
									<img className="w-72 w- text-center" src={NoDataImg} />
									<p className="font-bold">No data</p>
								</h3>
							</div>
						)
					) : null}

					{appliedPopup && (
						<Popup
							title="Leave Details"
							trigger={appliedPopup}
							setTrigger={setAppliedPopup}
							popupstyle="md:w-3/5 w-full"
						>
							<div className="w-full flex flex-col justify-center items-center">
								<Table
									tableStyle="border-spacing-y-[10px] border-separate -mt-4"
									header={lmsAppliedLeaveHead}
									body={latestLeave.map((row) => [
										row.category,
										row.no_of_days,
										row.start_date,
										row.end_date,
										row.description,
									])}
									paginationNeed={true}
									currentPage={currentPage}
									rowPerPage={rowPerPage}
									paginationBody={latestLeave}
									onPageChange={handlePageChange}
								/>
							</div>
						</Popup>
					)}

					{popupItem && (
						<Popup
							title="Leave Details"
							trigger={popupItem}
							setTrigger={setPopupItem}
							popupstyle="md:w-2/5 w-full"
						>
							<div className="w-full flex flex-col justify-center items-center">
								<LmsLeaveDetails label="Name" item={popupItem.username} />
								<LmsLeaveDetails label="Email" item={popupItem.email} />
								<LmsLeaveDetails label="Applied Date" item={popupItem.date} />
								<LmsLeaveDetails
									label="Leave Start Date"
									item={popupItem.start_date}
								/>
								<LmsLeaveDetails
									label="Leave End Date"
									item={popupItem.end_date}
								/>
								<LmsLeaveDetails label="Leave Type" item={popupItem.type} />
								<LmsLeaveDetails
									label="No Of days of leave requested"
									item={popupItem.no_of_days}
								/>
								<LmsLeaveDetails
									label="Sick leave remaining"
									item={popupItem.delta_sick}
								/>
								<LmsLeaveDetails
									label="Casual leave remaining"
									item={popupItem.delta_casual}
								/>
								<LmsLeaveDetails
									label="Optional leave remaining"
									item={popupItem.delta_optional}
								/>
								<LmsLeaveDetails
									label="Leave discription"
									item={popupItem.description}
								/>
								<LmsLeaveDetails
									label="Leave decision"
									item={popupItem.decision}
								/>
								<div className="w-full my-2 flex justify-center items-center">
									<Button
										classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
										onClick={(id) => handleDeleteRow(popupItem.id)}
									>
										Delete
									</Button>
								</div>
							</div>
						</Popup>
					)}

					{user.user_role === "MANAGER" ||
					user.user_role === "RMG" ||
					user.user_role === "CEO" ||
					user.user_role === "HR" ? (
						<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full mt-4 mb-4 pb-4">
							<div className="w-full intro-x bg-blue-900 flex flex-col md:flex-row rounded-t-3xl items-center py-3">
								<div className="flex-none px-4 my-2">
									{lmsYear && (
										<Dropdown
											style="md:px-4 px-0 bg-blue-900 text-white text-sm"
											value={lmsApproval}
											onChange={handleLmsApproval}
											options={lmsYear.map((option) => ({
												label: option,
												value: option,
											}))}
										/>
									)}
								</div>
								<div className="mx-auto flex-auto w-full md:w-96">
									<h4 className="table-header-text text-center font-bold text-sm md:text-lg">
										Latest Leave Applications For Approval
									</h4>
								</div>
								<div className="flex-none px-4 my-2">
									<Dropdown
										style="md:px-4 px-0 bg-blue-900 text-white"
										value={pagination}
										onChange={paginatedData}
										options={dropDownPagination}
									/>
								</div>
							</div>
							{latestLeaveForApproval.length > 0 ? (
								<Table
									header={lmsTableHeadForManager}
									body={latestLeaveForApproval.map((row) => [
										row.username,
										row.category,
										row.date,
										row.manager_level_approval === false
											? "Rejected"
											: row.manager_level_approval === true
											? "Approved"
											: "Pending",
										row.hr_level_approval === false
											? "Rejected"
											: row.hr_level_approval === true
											? "Approved"
											: "Pending",
									])}
									btnTitle="View"
									pageRows={5}
									paginationNeed={true}
									currentPage={currentPage}
									rowPerPage={rowPerPage}
									paginationBody={latestLeaveForApproval}
									onPageChange={handlePageChange}
									onClick={handleManagerPopupRow}
									tableStyle="intro-x"
								/>
							) : (
								<h3 className="text-center flex flex-col justify-center intro-x items-center my-4">
									<img className="w-72 w- text-center" src={NoDataImg} />
									<p className="font-bold">No data</p>
								</h3>
							)}
							{approvalPopupItem && (
								<Popup
									title="Leave Details"
									trigger={approvalPopupItem}
									setTrigger={setApprovalPopupItem}
									popupstyle="md:w-2/5 w-full"
								>
									<div className="w-full flex flex-col justify-center items-center">
										<LmsLeaveDetails
											label="Name"
											item={approvalPopupItem.application.username}
										/>
										<LmsLeaveDetails
											label="Email"
											item={approvalPopupItem.application.email}
										/>
										<LmsLeaveDetails
											label="Applied Date"
											item={approvalPopupItem.application.date}
										/>
										<LmsLeaveDetails
											label="Leave Start Date"
											item={approvalPopupItem.application.start_date}
										/>
										<LmsLeaveDetails
											label="Leave End Date"
											item={approvalPopupItem.application.end_date}
										/>
										<LmsLeaveDetails
											label="Leave Type"
											item={approvalPopupItem.application.category}
										/>
										<LmsLeaveDetails
											label="No Of days of leave requested"
											item={approvalPopupItem.application.no_of_days}
										/>
										<LmsLeaveDetails
											label="Sick leave remaining"
											item={approvalPopupItem.application.delta_sick}
										/>
										<LmsLeaveDetails
											label="Casual leave remaining"
											item={approvalPopupItem.application.delta_casual}
										/>
										<LmsLeaveDetails
											label="Optional leave remaining"
											item={approvalPopupItem.application.delta_optional}
										/>
										<LmsLeaveDetails
											label="Leave description"
											item={approvalPopupItem.application.description}
										/>
										<LmsLeaveDetails
											label="Leave decision"
											item={
												approvalPopupItem.application.decision === ""
													? "Pending"
													: approvalPopupItem.application.decision
											}
										/>
										{approvalPopupItem.edit_access === "True" && (
											<div className="w-full my-2 gap-2 flex justify-center items-center">
												<Button
													classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
													onClick={(id) =>
														handleApprove(approvalPopupItem.application.id)
													}
												>
													Approve
												</Button>
												<Button
													classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
													onClick={(id) =>
														handleReject(approvalPopupItem.application.id)
													}
												>
													Reject
												</Button>
											</div>
										)}
									</div>
								</Popup>
							)}
							{descriptionPopup && (
								<Popup
									title="Add Your Comments"
									trigger={descriptionPopup}
									setTrigger={setDescriptionPopup}
									popupstyle="md:w-80 w-full"
								>
									<form
										className="p-4 flex flex-col gap-2 justify-center items-center"
										onSubmit={handleDescribBox}
									>
										<Textarea
											value={descripValue}
											style="w-full h-32"
											onChange={handleTextAreaChange}
											required={true}
										/>
										<Button classname="w-auto text-white text-center text-sm font-semibold py-2 rounded-md px-6 bg-button">
											Submit
										</Button>
									</form>
								</Popup>
							)}
						</div>
					) : null}

					{user.user_role === "EMPLOYEE" ||
					user.user_role === "INTERN" ||
					user.user_role === "TRAINEE" ||
					user.user_role === "HR" ||
					user.user_role === "MANAGER" ||
					user.user_role === "RMG" ||
					user.user_role === "CONSULTANT" ? (
						wfh.length > 0 ? (
							<>
								<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full pb-4 mb-4 mt-4">
									<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3">
										<h4 className="mx-6 text-center table-header-text font-bold text-sm md:text-lg">
											Work From Home Applications
										</h4>
									</div>
									<Table
										header={wfhTableHead}
										body={wfh.map((row) => [
											row.date,
											row.no_of_days,
											row.manager_level_approval === false
												? "Rejected"
												: row.manager_level_approval === true
												? "Approved"
												: "Pending",
											row.hr_level_approval === false
												? "Rejected"
												: row.hr_level_approval === true
												? "Approved"
												: "Pending",
										])}
										btnTitle="View"
										paginationNeed={false}
										currentPage={currentPage}
										rowPerPage={rowPerPage}
										paginationBody={wfh}
										onPageChange={handlePageChangetwo}
										onClick={handlePopupRowWfh}
										tableStyle="intro-x"
									/>
								</div>
							</>
						) : (
							<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full pb-4 mb-4 mt-4">
								<div className="w-full intro-x bg-blue-900 flex rounded-t-3xl justify-center items-center py-3">
									<h4 className="mx-6 text-center table-header-text font-bold text-sm md:text-lg">
										Work From Home Applications
									</h4>
								</div>
								<h3 className="text-center flex flex-col justify-center items-center intro-x my-4">
									<img className="w-72 w- text-center" src={NoDataImg} />
									<p className="font-bold">No data</p>
								</h3>
							</div>
						)
					) : null}

					{popupItemWfh && (
						<Popup
							title="Work From Home Details"
							trigger={popupItemWfh}
							setTrigger={setPopupItemWfh}
							popupstyle="md:w-2/5 w-full"
						>
							<div className="w-full flex flex-col justify-center items-center">
								<LmsLeaveDetails label="Name" item={popupItemWfh.username} />
								<LmsLeaveDetails label="Email" item={popupItemWfh.email} />
								<LmsLeaveDetails
									label="Applied Date"
									item={popupItemWfh.date}
								/>
								<LmsLeaveDetails
									label="WFH Start Date"
									item={popupItemWfh.start_date}
								/>
								<LmsLeaveDetails
									label="WFH End Date"
									item={popupItemWfh.end_date}
								/>
								<LmsLeaveDetails
									label="No Of days of WFH requested"
									item={popupItemWfh.no_of_days}
								/>
								<LmsLeaveDetails
									label="WFH discription"
									item={popupItemWfh.description}
								/>
								<LmsLeaveDetails
									label="WFH decision"
									item={popupItemWfh.decision}
								/>
								<div className="w-full my-2 flex justify-center items-center">
									<Button
										classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
										onClick={(id) => handleDeleteRowWfh(popupItemWfh.id)}
									>
										Delete
									</Button>
								</div>
							</div>
						</Popup>
					)}

					{user.user_role === "MANAGER" ||
					user.user_role === "CEO" ||
					user.user_role === "RMG" ||
					user.user_role === "HR" ? (
						<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full mt-4 mb-4 pb-4">
							<div className="w-full intro-x bg-blue-900 flex flex-col md:flex-row rounded-t-3xl items-center py-3">
								<div className="flex-none px-4 my-2">
									{lmsYear && (
										<Dropdown
											style="md:px-4 px-0 bg-blue-900 text-white text-sm"
											value={wfhApproval}
											onChange={handleWfhApproval}
											options={lmsYear.map((option) => ({
												label: option,
												value: option,
											}))}
										/>
									)}
								</div>
								<div className="mx-auto flex-auto w-full md:w-96">
									<h4 className="table-header-text text-center font-bold text-sm md:text-lg">
										Work From Home Applications For Approval
									</h4>
								</div>
								<div className="flex-none px-4 my-2">
									<Dropdown
										style="md:px-4 px-0 bg-blue-900 text-white"
										value={paginationWfh}
										onChange={paginatedDataWfh}
										options={dropDownPagination}
									/>
								</div>
							</div>
							{wfhForApproval.length > 0 ? (
								<Table
									header={wfhTableHeadForManager}
									body={wfhForApproval.map((row) => [
										row.username,
										row.date,
										row.manager_level_approval === false
											? "Rejected"
											: row.manager_level_approval === true
											? "Approved"
											: "Pending",
										row.hr_level_approval === false
											? "Rejected"
											: row.hr_level_approval === true
											? "Approved"
											: "Pending",
									])}
									btnTitle="View"
									pageRows={5}
									paginationNeed={true}
									currentPage={currentPageWfh}
									rowPerPage={rowPerPage}
									paginationBody={wfhForApproval}
									onPageChange={handlePageChangethree}
									onClick={handleManagerPopupRowWfh}
									tableStyle="intro-x"
								/>
							) : (
								<h3 className="text-center flex flex-col justify-center intro-x items-center my-4">
									<img className="w-72 w- text-center" src={NoDataImg} />
									<p className="font-bold">No data</p>
								</h3>
							)}
							{approvalPopupItemWfh && (
								<Popup
									title="Work From Home Details"
									trigger={approvalPopupItemWfh}
									setTrigger={setApprovalPopupItemWfh}
									popupstyle="md:w-2/5 w-full"
								>
									<div className="w-full flex flex-col justify-center items-center">
										<LmsLeaveDetails
											label="Name"
											item={approvalPopupItemWfh.application.username}
										/>
										<LmsLeaveDetails
											label="Email"
											item={approvalPopupItemWfh.application.email}
										/>
										<LmsLeaveDetails
											label="Applied Date"
											item={approvalPopupItemWfh.application.date}
										/>
										<LmsLeaveDetails
											label="WFH Start Date"
											item={approvalPopupItemWfh.application.start_date}
										/>
										<LmsLeaveDetails
											label="WFH End Date"
											item={approvalPopupItemWfh.application.end_date}
										/>
										<LmsLeaveDetails
											label="No Of days of WFH requested"
											item={approvalPopupItemWfh.application.no_of_days}
										/>
										<LmsLeaveDetails
											label="WFH description"
											item={approvalPopupItemWfh.application.description}
										/>
										<LmsLeaveDetails
											label="WFH decision"
											item={
												approvalPopupItemWfh.application.decision === ""
													? "Pending"
													: approvalPopupItemWfh.application.decision
											}
										/>
										{approvalPopupItemWfh.edit_access === "True" && (
											<div className="w-full my-2 gap-2 flex justify-center items-center">
												<Button
													classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
													onClick={(id) =>
														handleApproveWfh(
															approvalPopupItemWfh.application.id
														)
													}
												>
													Approve
												</Button>
												<Button
													classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
													onClick={(id) =>
														handleRejectWfh(approvalPopupItemWfh.application.id)
													}
												>
													Reject
												</Button>
											</div>
										)}
									</div>
								</Popup>
							)}
							{descriptionPopupWfh && (
								<Popup
									title="Add Your Comments"
									trigger={descriptionPopupWfh}
									setTrigger={setDescriptionPopupWfh}
									popupstyle="md:w-80 w-full"
								>
									<form
										className="p-4 flex flex-col gap-2 justify-center items-center"
										onSubmit={handleDescribBoxWfh}
									>
										<Textarea
											value={descripValueWfh}
											style="w-full h-32"
											onChange={handleTextAreaChangeWfh}
											required={true}
										/>
										<Button classname="w-auto text-white text-center text-sm font-semibold py-2 rounded-md px-6 bg-button">
											Submit
										</Button>
									</form>
								</Popup>
							)}
						</div>
					) : null}

					{reportTo === true ? (
						user.user_role === "EMPLOYEE" ||
						user.user_role === "CONSULTANT" ||
						user.user_role === "TRAINEE" ? (
							<div className="box-shadow bg-accent transition flex flex-col items-center justify-center rounded-3xl w-full mt-4 mb-4 pb-4">
								<div className="w-full intro-x bg-blue-900 flex flex-col md:flex-row rounded-t-3xl items-center py-3">
									<div className="flex-none px-4 my-2">
										{lmsYear && (
											<Dropdown
												style="md:px-4 px-0 bg-blue-900 text-white text-sm"
												value={lmsApproval}
												onChange={handleLmsApproval}
												options={lmsYear.map((option) => ({
													label: option,
													value: option,
												}))}
											/>
										)}
									</div>
									<div className="mx-auto flex-auto w-full md:w-96">
										<h4 className="table-header-text text-center font-bold text-sm md:text-lg">
											Latest Leave Applications For Approval
										</h4>
									</div>
									<div className="flex-none px-4 my-2">
										<Dropdown
											style="md:px-4 px-0 bg-blue-900 text-white"
											value={pagination}
											onChange={paginatedData}
											options={dropDownPagination}
										/>
									</div>
								</div>
								{latestLeaveForApproval.length > 0 ? (
									<Table
										header={lmsTableHeadForManager}
										body={latestLeaveForApproval.map((row) => [
											row.username,
											row.category,
											row.date,
											row.manager_level_approval === false
												? "Rejected"
												: row.manager_level_approval === true
												? "Approved"
												: "Pending",
											row.hr_level_approval === false
												? "Rejected"
												: row.hr_level_approval === true
												? "Approved"
												: "Pending",
										])}
										btnTitle="View"
										pageRows={5}
										paginationNeed={true}
										currentPage={currentPage}
										rowPerPage={rowPerPage}
										paginationBody={latestLeaveForApproval}
										onPageChange={handlePageChange}
										onClick={handleManagerPopupRow}
										tableStyle="intro-x"
									/>
								) : (
									<h3 className="text-center flex flex-col justify-center intro-x items-center my-4">
										<img className="w-72 w- text-center" src={NoDataImg} />
										<p className="font-bold">No data</p>
									</h3>
								)}
								{approvalPopupItem && (
									<Popup
										title="Leave Details"
										trigger={approvalPopupItem}
										setTrigger={setApprovalPopupItem}
										popupstyle="md:w-2/5 w-full"
									>
										<div className="w-full flex flex-col justify-center items-center">
											<LmsLeaveDetails
												label="Name"
												item={approvalPopupItem.application.username}
											/>
											<LmsLeaveDetails
												label="Email"
												item={approvalPopupItem.application.email}
											/>
											<LmsLeaveDetails
												label="Applied Date"
												item={approvalPopupItem.application.date}
											/>
											<LmsLeaveDetails
												label="Leave Start Date"
												item={approvalPopupItem.application.start_date}
											/>
											<LmsLeaveDetails
												label="Leave End Date"
												item={approvalPopupItem.application.end_date}
											/>
											<LmsLeaveDetails
												label="Leave Type"
												item={approvalPopupItem.application.category}
											/>
											<LmsLeaveDetails
												label="No Of days of leave requested"
												item={approvalPopupItem.application.no_of_days}
											/>
											<LmsLeaveDetails
												label="Sick leave remaining"
												item={approvalPopupItem.application.delta_sick}
											/>
											<LmsLeaveDetails
												label="Casual leave remaining"
												item={approvalPopupItem.application.delta_casual}
											/>
											<LmsLeaveDetails
												label="Optional leave remaining"
												item={approvalPopupItem.application.delta_optional}
											/>
											<LmsLeaveDetails
												label="Leave description"
												item={approvalPopupItem.application.description}
											/>
											<LmsLeaveDetails
												label="Leave decision"
												item={
													approvalPopupItem.application.decision === ""
														? "Pending"
														: approvalPopupItem.application.decision
												}
											/>
											{approvalPopupItem.edit_access === "True" && (
												<div className="w-full my-2 gap-2 flex justify-center items-center">
													<Button
														classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
														onClick={(id) =>
															handleApprove(approvalPopupItem.application.id)
														}
													>
														Approve
													</Button>
													<Button
														classname="bg-[rgb(30,64,175)] box-shadow p-2 rounded-md text-white px-3 text-sm"
														onClick={(id) =>
															handleReject(approvalPopupItem.application.id)
														}
													>
														Reject
													</Button>
												</div>
											)}
										</div>
									</Popup>
								)}
								{descriptionPopup && (
									<Popup
										title="Add Your Comments"
										trigger={descriptionPopup}
										setTrigger={setDescriptionPopup}
										popupstyle="md:w-80 w-full"
									>
										<form
											className="p-4 flex flex-col gap-2 justify-center items-center"
											onSubmit={handleDescribBox}
										>
											<Textarea
												value={descripValue}
												style="w-full h-32"
												onChange={handleTextAreaChange}
												required={true}
											/>
											<Button classname="w-auto text-white text-center text-sm font-semibold py-2 rounded-md px-6 bg-button">
												Submit
											</Button>
										</form>
									</Popup>
								)}
							</div>
						) : null
					) : null}
				</>
			)}
		</div>
	);
};

export default LmsDashboard;
