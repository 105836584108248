import React, { useContext } from "react";
import { Sidebar, Layout } from "../components";
import AuthContext from "../contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";
import {
	payrollItems,
	payrollItemsForManager,
	payrollItemsForHR,
	payrollItemsForCeo,
} from "../data";
import {
	Hrpolicy,
	PayrollDashboard,
	PayrollForm,
	Payslips,
	PayslipGeneration,
	PayslipUpdate,
	FormSixteenUpdation,
} from "../sub-pages/PayrollPages";

const PayrollPage = () => {
	const history = useHistory();
	const { user } = useContext(AuthContext);

	if (!user) {
		return history.push("/login");
	}
	return (
		<>
			{user.user_role === "EMPLOYEE" ||
			user.user_role === "CONSULTANT" ||
			user.user_role === "TRAINEE" ? (
				<Layout sidebar={<Sidebar sidebarLinks={payrollItems} />}>
					<Route path="/payroll/dashboard" component={PayrollDashboard} />
					<Route path="/payroll/pay-slips" component={Payslips} />
					<Route path="/payroll/form-16" component={PayrollForm} />
					<Route path="/payroll/hr-policy" component={Hrpolicy} />
				</Layout>
			) : null}

			{user.user_role === "MANAGER" || user.user_role === "RMG" ? (
				<Layout sidebar={<Sidebar sidebarLinks={payrollItemsForManager} />}>
					<Route path="/payroll/dashboard" component={PayrollDashboard} />
					<Route path="/payroll/pay-slips" component={Payslips} />
					<Route path="/payroll/form-16" component={PayrollForm} />
					<Route path="/payroll/hr-policy" component={Hrpolicy} />
				</Layout>
			) : null}

			{user.user_role === "CEO" ? (
				<Layout sidebar={<Sidebar sidebarLinks={payrollItemsForCeo} />}>
					<Route path="/payroll/dashboard" component={PayrollDashboard} />
					<Route path="/payroll/hr-policy" component={Hrpolicy} />
				</Layout>
			) : null}

			{user.user_role === "HR" ? (
				<Layout sidebar={<Sidebar sidebarLinks={payrollItemsForHR} />}>
					<Route path="/payroll/dashboard" component={PayrollDashboard} />
					<Route path="/payroll/pay-slip-updation" component={PayslipUpdate} />
					<Route
						path="/payroll/form-16-updation"
						component={FormSixteenUpdation}
					/>
					<Route
						path="/payroll/pay-slip-generation"
						component={PayslipGeneration}
					/>
					<Route path="/payroll/pay-slips" component={Payslips} />
					<Route path="/payroll/form-16" component={PayrollForm} />
					<Route path="/payroll/hr-policy" component={Hrpolicy} />
				</Layout>
			) : null}
		</>
	);
};

export default PayrollPage;
